import React from 'react'
import styled, { keyframes } from 'styled-components'
import { fadeIn } from 'react-animations'

import ImageDiv from 'components/styled/ImageDiv'
import { ReactComponent as Arrow } from 'icons/down-arrow.svg'

export default ({ src, subTitle, title }) => {
  return (
    <BackgroundImage image={src}>
      <Overlay>
        <Title>{title}</Title>
        <Divider />
        <Subtitle>{subTitle}</Subtitle>
        <ArrowIcon />
      </Overlay>
    </BackgroundImage>
  )
}

const BackgroundImage = styled(ImageDiv)`
  background-attachment: fixed;
`

const fadeAnimation = keyframes` ${fadeIn} `

const Overlay = styled.div`
  margin: 0;
  padding: 120px 0 50px;
  text-align: center;
  background-color: rgba(18, 20, 44, 0.7);

  @media (max-width: 685px) {
    padding: 60px 0 45px;
  }

  @media (max-width: ${(props) => props.theme.desktop}px) {
    padding: 60px 0 65px;
  }
`

const Title = styled.div`
  margin: 0 0;
  padding: 0;
  font-family: ${(props) => props.theme.headingFont};
  font-size: 55pt;
  color: white;
  animation: 1s ${fadeAnimation};

  @media (max-width: ${(props) => props.theme.desktop}px) {
    font-size: 25pt;
  }
`
const Divider = styled.div`
  margin: 20px auto;
  width: 85px;
  height: 3px;
  background: ${(props) => props.theme.lightBlue};
  animation: 1s ${fadeAnimation};

  @media (max-width: ${(props) => props.theme.desktop}px) {
    margin: 30px auto;
  }
`
const Subtitle = styled.div`
  margin: 0 auto 80px;
  padding: 0;
  color: white;
  font-family: ${(props) => props.theme.bodyFont};
  font-size: 22pt;
  line-height: 28pt;
  font-weight: 200;
  width: 50%;
  animation: 1s ${fadeAnimation};

  @media (max-width: ${(props) => props.theme.desktop}px) {
    font-size: 16pt;
    line-height: 20pt;
    width: 90%;
    margin: 0 auto 30px;
  }
`

const ArrowIcon = styled(Arrow)`
  margin: 0 auto;
  width: 45px;
  height: 20px;
  cursor: pointer;
`
