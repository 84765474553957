import React, { Fragment } from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'

import Seo from 'components/SEO'
import Header from './Header'
// import MapSection from './MapSection'

export default ({ data }) => {
  const { frontmatter } = data.markdownRemark
  const { seo, header, mapSection } = frontmatter
  return (
    <Fragment>
      <Seo title={seo.title} description={seo.description} />
      <Helmet title={seo.title} />
      <Header
        title={header.title}
        subTitle={header.subTitle}
        src={header.image.childImageSharp.gatsbyImageData.images.fallback.src}
      />
      {/* <MapSection
        title={mapSection.title}
        content={mapSection.description}
        mapMarker={
          mapSection.marker.childImageSharp.gatsbyImageData.images.fallback.src
        }
      /> */}
    </Fragment>
  )
}

export const query = graphql`
  query NewFacilityPageQuery($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        seo {
          title
          description
        }
        header {
          title
          subTitle
          image {
            childImageSharp {
              gatsbyImageData(sizes: "2200")
            }
          }
        }
        mapSection {
          title
          description
          marker {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
`
